*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,
body
{
    height: 100vh;
    font-family: 'Poppins';
}

header
{
    height: fit-content;
    grid-column: 1/13;
    padding: 2rem;
} 

body
{
    margin: 0;
    padding: 0;
    font-family: Arial Black;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size:cover;
    background-position:center;
    box-sizing: border-box;
    display: grid;
    overflow-x: hidden;
    grid-template-columns: repeat(12, 1fr);
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    mix-blend-mode: exclusion;
    pointer-events: none;
}

.container{
    grid-column: 2/12;
    display: grid;
    text-align: center;
    margin-top: 25vh;
}


section{
    height: 500vh;
}

.container2{
    margin-top: 120vh;
    grid-column: 5/12;
    display: grid;
    text-align: center;
    height: fit-content;
    opacity: 0;
    filter: blur(1vh);
    transform: translateX(100%);
    transition: all 1s;
    background-color:rgba(169,169,169,0.5);
}

.show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

/* .logo
{
    float:left;
    text-align: center;
    font-size: 3rem;
    padding: 0 20px;
    color:#069370;
} */
/* 
.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: gray;
    color: white;
} */


.nav-links ul {
    margin: 0;
    padding: 0;
    display: flex;
    text-align: center;
}

.nav-links li {
    list-style: none;
    
}

.nav-links li a{
    text-decoration: none;
    color: white;
    padding: 1rem;
    display: block;
    opacity: .7;
}

.nav-links li:hover{
    background-color: #555;
}

.toggle-button{
position: absolute;
top:2rem;
right: 1rem;
display: none;
flex-direction: column;
justify-content: space-between;
width: 30px;
height: 21px;
}

.toggle-button .bar{
    height: 3px;
    width: 100%;
    background-color: white;
    border-radius: 10px;

}

@media(prefers-reduced-motion){
    .container2{
        transition: none;
    }
}

@media only screen and (max-width: 600px ) {

   .toggle-button{
    display:flex;
   }

   .nav-links{
     display: none; 
    width: 100%;
   }

   .navbar{
    flex-direction: column;
    align-items: flex-start;

   }

   .nav-links ul {
    width: 100%;
    flex-direction: column;
   }

   .nav-links li {
    text-align: center;
   }

   .nav-links.active{
    display: flex;
   }

    h1{
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
h2{
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;

}
h3{
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
h4{
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
}
p{
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;

}

}

@media only screen and (min-width: 600px ) {

    h1{
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
    h2{
        font-size: 3rem;
        text-transform: uppercase;
        color: white;
        font-family: Arial Black;
        opacity: 0.5;
    
}
h3{
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
h4{
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
p{
    font-size: 2rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;

}

}

@media only screen and (min-width: 768px ) {

    h1{
    font-size: 5rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
h2{
    font-size: 5rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;

}
h3{
    font-size: 5rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
h4{
    font-size: 5rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;
    
}
p{
    font-size: 3rem;
    text-transform: uppercase;
    color: white;
    font-family: Arial Black;
    opacity: 0.5;

}
}